/**
 *
 * Description. Login Private Route
 *
 * @link   URL
 * @file   Whenever user comes to login page then this function gets called.
           It checks whether user is already logged in or not and redirected to
           pages accordingly. If user is not logged in then it will open login page
 * @since  1.0.0
 */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import app from '../helpers/appGlobal';

const LoginPrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (app.user.id) {
        if (app.user.userType === 'rep') {
          return <Redirect to="/RepDashboard" />;
        }
        return <Redirect to="/SearchRep" />;
      }
      return <Component {...props} />;
    }}
  />
);

export default LoginPrivateRoute;
