/* eslint no-param-reassign: 0 */
/**
 *
 * Description. Localstorage
 *
 * @link   URL
 * @file   Getter and setter functions for localStorage
 * @since  1.0.0
 */
// import { enc } from 'crypto-js';
import app from '../helpers/appGlobal';

const { encrypt, decrypt } = require('../config/encrypt-decrypt');

const decryptData = (data) => {
  try {
    return JSON.parse(decrypt(data, app.config.AES_ENCRYPTION_KEY)) || {};
  } catch (e) {
    return {};
  }
};

const encryptData = (data) => {
  return encrypt(JSON.stringify(data), app.config.AES_ENCRYPTION_KEY);
};

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const getLocalStorage = (key) => {
  if (getCookie(key)) {
    const value = sessionStorage.getItem(key);
    return value ? decryptData(value) : {};
  }
  return {};
};

export const setToLocalStorage = (value, key) => {
  sessionStorage.setItem(key, encryptData(value));
  setCookie(key, encryptData(value), 1);
};

export const removeFromLocalStorage = (key) => {
  sessionStorage.removeItem(key);
};
