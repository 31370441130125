/**
 *
 * Description. Common Utility function for API call
 *
 * @link   URL
 * @file   API call's can be made from this function (GET and POST request)
 * @since  1.0.0
 */
import axios from 'axios';
import sessionErrorHandle from './sessionErrorHandle';
import app from '../helpers/appGlobal';

const baseApiURL = `${app.config.API_URL}/api`;

const errorRes = (error) => {
  return {
    error: true,
    message: error.message,
  };
};

const axiosApi = (
  url,
  method,
  reqPayload,
  cb,
  cancelToken,
  otherConfig,
  id
) => {
  const newUrl = `${baseApiURL}${url}`;
  const config = {
    ...otherConfig,
  };
  if (cancelToken) {
    config.cancelToken = cancelToken; // used for cancelling the API request
  }

  if (app.user.token) {
    // Access token header
    config.headers = {
      Authorization: `${app.user.token}`,
    };
  }

  if (method === 'POST') {
    axios
      .post(newUrl, reqPayload, config)
      .then((response) => {
        if (sessionErrorHandle(response.data)) {
          cb(response.data, id);
        }
      })
      .catch((error) => {
        cb(errorRes(error), id);
      });
  } else if (method === 'GET') {
    axios
      .get(newUrl, config)
      .then((response) => {
        if (sessionErrorHandle(response.data)) {
          cb(response.data, id);
        }
      })
      .catch((error) => {
        cb(errorRes(error), id);
      });
  }
};

const axiosApiNew = async (
  url,
  method,
  reqPayload,
  cancelToken,
  otherConfig
) => {
  const newUrl = `${baseApiURL}${url}`;
  const config = {
    ...otherConfig,
  };
  if (cancelToken) {
    config.cancelToken = cancelToken; // used for cancelling the API request
  }

  if (app.user.token) {
    // Access token header
    config.headers = {
      Authorization: `${app.user.token}`,
    };
  }

  if (method === 'POST') {
    const resPost = await axios
      .post(newUrl, reqPayload, config)
      .catch((error) => {
        return errorRes(error);
      });
    if (sessionErrorHandle(resPost.data)) {
      return resPost.data;
    }
  } else if (method === 'GET') {
    const resGet = await axios.get(newUrl, config).catch((error) => {
      return errorRes(error);
    });
    if (sessionErrorHandle(resGet.data)) {
      return resGet.data;
    }
  }
  return null;
};

export { axiosApi, axiosApiNew };
