/**
 *
 * Description. Session error (401)
 *
 * @link   URL
 * @file   In any API, 401 session timeout error is handled from Here
           Redirected to login page if error found
 * @since  1.0.0
 */
import { removeOnLogout } from '../utils/editUpdateStorage';

const sessionErrorHandle = (res) => {
  if (res.code === '401') {
    removeOnLogout();
    return false;
  }
  return true;
};

export default sessionErrorHandle;
