/**
 *
 * Description. Localstorage
 *
 * @link   URL
 * @file   Handles all the localstorage functions like add, remove and get
           Stores user login session info and retrive it.
 * @since  1.0.0
 */
import app from '../helpers/appGlobal';
import {
  getLocalStorage,
  setToLocalStorage,
  removeFromLocalStorage,
  getCookie,
} from './storage';
import { messageBroadcast } from '../helpers/broadcastMessage';

const mainKey = 'loginRes';

export const setResData = (res) => {
  app.user = res;
};

export const setToStorage = (res) => {
  setToLocalStorage(res, mainKey);
};

export const getLocalStorageData = () => {
  return getLocalStorage(mainKey);
};

export const getCookieData = () => {
  return getCookie(mainKey);
};

export const getAndSetData = () => {
  setResData(getLocalStorageData());
};

// update specific values for user
export const updateToLocalStorage = (dataArr) => {
  const res = getLocalStorageData();
  dataArr.forEach((v) => {
    const { name, value } = v;
    app.user[name] = value;
    res[name] = value;
  });
  setToStorage(res);
  /* Need to reload other tabs because user has verified OTP */
  messageBroadcast({
    command: 'reload',
    uid: new Date().getTime() + Math.random(),
  });
};

// Logout the user and remove localstorage data
export const removeOnLogout = (isNoReload) => {
  removeFromLocalStorage(mainKey);
  app.user = {};
  messageBroadcast({
    command: 'reload',
    uid: new Date().getTime() + Math.random(),
  });
  if (!isNoReload) {
    window.location.replace('/Login');
  }
};
