// This is used for sending events to other open tabs
export const messageBroadcast = (message) => {
  localStorage.setItem('messageBroadcast', JSON.stringify(message));
  localStorage.removeItem('messageBroadcast');
};

// receive message from other similar domain tabs
export const msgReceive = (ev) => {
  if (ev.key !== 'messageBroadcast') return; // ignore other keys
  const message = JSON.parse(ev.newValue);
  if (!message) return; // ignore empty msg or msg reset

  // here you act on messages.
  if (message.command === 'reload') {
    const { pathname } = window.location;
    if (
      !(
        pathname === '/Signup' ||
        pathname === '/ForgotPassword' ||
        pathname.startsWith('/ResetPassword/')
      )
    ) {
      window.location.reload();
    }
  }
};
