import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store.js';
import DOMReady from './helpers/domReady';
import RouterExport from './routers/routes';
import { getAndSetData } from './utils/editUpdateStorage';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './css/global.scss';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_REPCARDZ_ENV || 'development',
  });
}

DOMReady(); // Initialize dom events
getAndSetData(); // Set localstorage data to global variable if there

const renderPage = () => {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <RouterExport />
      </Router>
    </Provider>,
    document.getElementById('root')
  );
};
renderPage();
